import PortfolioContainer from "./container/PortfolioContainer";
import './App.css'

function App() {
  return (
    <div className="App">
       <PortfolioContainer />
    </div>
  );
}

export default App;
